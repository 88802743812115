/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import I18n, { translate } from '@utils/i18n';
import FilterPanel from '@components/FilterPanel';

import { DEFAULT_PAGE_SIZE, pageSizes, buildGridSettings } from '@constants/Kendo/gridSettings';
import { actionTypes, reducer, buildInitialFiltersState } from './reducer';
import SearchPanel from '../../../components/SearchPanel';
import FilterTypes from '../../../constants/FilterTypes.ts';

// Add JQuery
const { kendo } = window;
const { $, confirm, ConfirmationModal } = window;
const _ = require('lodash');

const DEFAULT_NAMESPACE = 'proposed_corrections.datatable';
const DEFAULT_CACHE_KEY = 'proposed_corrections/index';
const DEFAULT_NON_HIDABLE_COLUMNS = ['actions'];
const EDITABLE_FIELDS = ['listPrice', 'unitCost', 'price'];

// TODO: re-visit and see how we could minify the relationship between sprockets and webpacker part.
// Ideally, we should not mix them. But to be eliminate the waiting time for the duration
// of whole project being re-written and to be able to use React now - it's worth it.
function IndexTable(props) {
  const {
    namespace,
    proposedCorrectionsUrl,
    statusesForSelect,
    categoriesForSelect,
    serviceTypesForSelect,
    materialTypesForSelect,
    cacheKey,
    defaultHiddenColumns,
    nonHidableColumns,
    pageSize
  } = props;

  const kendoCacheKey = `kendo/${cacheKey}`;
  const containerRef = useRef();
  const tableRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    search: '',
    tableFingerprint: undefined,
    proposed_corrections: [],
    filters: buildInitialFiltersState()
  });

  // Mechanism to make state being up-to-date in Kendo grid callbacks.
  // Src: https://stackoverflow.com/a/60643670/20974169
  const filtersRef = useRef();
  filtersRef.current = state.filters;

  const tableFingerprintRef = useRef();
  tableFingerprintRef.current = state.tableFingerprint;

  const isMounted = !!state.tableFingerprint;
  const isMountedForCallbacks = () => !!tableFingerprintRef.current;

  useEffect(() => {
    setupTable();
    loadCacheFromLocalStorage();

    dispatch({ type: actionTypes.TABLE_RELOADED });
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    reloadTable();
  }, [state.tableFingerprint]);

  // ===================== JS Part ================================

  const setupTable = () => {
    const gridSettings = buildGridSettings(tableRef.current, {
      search: {
        fields: [{ name: 'name' }, { name: 'price', operator: 'contains' }, { name: 'manufacturer' }, { name: 'type' }]
      },
      columns: [
        {
          field: 'name',
          title: translate('table.headers.name', { namespace }),
          template: nameTemplate,
          hidden: defaultHiddenColumns.includes('name'),
          menu: !nonHidableColumns.includes('name')
        },
        {
          field: 'category',
          title: translate('table.headers.category', { namespace }),
          template: categoryTemplate,
          hidden: defaultHiddenColumns.includes('category'),
          menu: !nonHidableColumns.includes('category')
        },
        {
          field: 'type',
          title: translate('table.headers.type', { namespace }),
          sortable: false,
          template: typeTemplate,
          hidden: defaultHiddenColumns.includes('type'),
          menu: !nonHidableColumns.includes('type')
        },
        {
          field: 'manufacturer',
          title: translate('table.headers.manufacturer', { namespace }),
          template: manufacturerTemplate,
          hidden: defaultHiddenColumns.includes('manufacturer'),
          menu: !nonHidableColumns.includes('manufacturer')
        },
        {
          field: 'listPrice',
          title: translate('table.headers.list_price', { namespace }),
          template: listPriceTemplate.replace(/&quot;/g, '"'),
          hidden: defaultHiddenColumns.includes('listPrice'),
          menu: !nonHidableColumns.includes('listPrice')
        },
        {
          field: 'unitCost',
          title: translate('table.headers.unit_cost', { namespace }),
          template: unitCostTemplate.replace(/&quot;/g, '"'),
          hidden: defaultHiddenColumns.includes('unitCost'),
          menu: !nonHidableColumns.includes('unitCost')
        },
        {
          field: 'price',
          title: translate('table.headers.price', { namespace }),
          template: priceTemplate.replace(/&quot;/g, '"'),
          hidden: defaultHiddenColumns.includes('price'),
          menu: !nonHidableColumns.includes('price')
        },
        {
          field: 'isTaxable',
          title: translate('table.headers.is_taxable', { namespace }),
          template: isTaxableTemplate,
          hidden: defaultHiddenColumns.includes('isTaxable'),
          menu: !nonHidableColumns.includes('isTaxable')
        },
        {
          field: 'actions',
          title: ' ',
          sortable: false,
          columnMenu: false,
          template: actionsTemplate,
          hidden: defaultHiddenColumns.includes('actions'),
          menu: !nonHidableColumns.includes('actions'),
          attributes: {
            class: 'table__cell--options--col-actions'
          }
        }
      ],
      pageable: {
        input: true,
        numeric: false,
        pageSize,
        pageSizes: pageSizes(pageSize)
      },
      dataSource: {
        transport: {
          update: {
            url(data) {
              return data.updateLink;
            },
            dataType: 'json',
            type: 'PATCH'
          },
          parameterMap(options, operation) {
            if (operation === 'update') {
              const params = _.transform(_.pick(options, EDITABLE_FIELDS), (result, val, key) => {
                result[_.snakeCase(key)] = val;
              });

              return { proposed_correction: params };
            }

            return options;
          }
        },
        schema: {
          model: {
            id: 'id',
            fields: {
              id: { editable: false, type: 'number' },
              name: { editable: false, type: 'string' },
              description: { editable: false, type: 'string' },
              category: { editable: false, type: 'string' },
              type: { editable: false, type: 'string' },
              isActive: { editable: false, type: 'string' },
              manufacturer: { editable: false, type: 'string' },
              modelNumber: { editable: false, type: 'string' },
              updateLink: { type: 'string' },
              editLink: { type: 'string' },
              listPrice: { type: 'number', validation: { required: true, min: 0, max: 999999.99 } },
              unitCost: { type: 'number', validation: { required: true, min: 0, max: 999999.99 } },
              price: { type: 'number', validation: { required: true, min: 0, max: 9999999999999.99 } },
              isTaxable: { editable: false, type: 'string' },
              actions: { editable: false }
            }
          }
        },
        sort: { field: 'name', dir: 'asc' },
        autoSync: true
      },
      scrollable: false,
      editable: true,
      columnShow: saveCacheToLocalStorage,
      columnHide: saveCacheToLocalStorage,
      dataBinding: () => {
        $('.rerender-proposed-corrections-popup').remove();
      },
      dataBound: () => {
        new ConfirmationModal().init();
        saveCacheToLocalStorage();
        // eslint-disable-next-line no-undef
        loadDropdowns({ gridSelector: '#proposed-corrections-grid' });
      }
    });
    $(tableRef.current).kendoGrid(gridSettings);

    // Remove the default event handler that is attached to the search input and add a custom one.
    $(tableRef.current).data('kendoGrid').wrapper.find('.k-grid-toolbar').off('input.kendoGrid');
  };

  const nameTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (name) { #'}
      <span>#= name #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const categoryTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (category) { #'}
      <span>#= category #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const typeTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (type) { #'}
      <span>#= type #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const manufacturerTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (manufacturer) { #'}
      <span>#= manufacturer #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const listPriceTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (listPrice !== null) { #'}
      <span>#= kendo.toString(listPrice, "c2") #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const unitCostTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (unitCost !== null) { #'}
      <span>#= kendo.toString(unitCost, "c2") #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const priceTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (price !== null) { #'}
      <span>#= kendo.toString(price, "c2") #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const isTaxableTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (isTaxable) { #'}
      <span>#= isTaxable #</span>
      {'# } else { #'}
      <span>N/A</span>
      {'# } #'}
    </>
  );

  const actionsTemplate = ReactDOMServer.renderToString(
    <div>
      <button type="button" className="qmb-control--icon--row-actions --react">
        <i className="fa-regular fa-ellipsis" />
      </button>
      <div
        id="proposed_corrections_popup_#= id #"
        className="qmb-popup--action-list rerender-proposed-corrections-popup">
        <ul role="presentation">
          <li>
            <a
              href="#= editLink #"
              title={I18n.t('generic.edit_details')}
              className="qmb-control"
              target="blank"
              rel="noopener noreferrer">
              <i className="fa-light fa-pen-to-square" /> {I18n.t('generic.edit_details')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const searchHandler = (e) => {
    dispatch({ type: actionTypes.SEARCH_CHANGED, search: e });
    dispatch({ type: actionTypes.TABLE_RELOADED });
  };

  const saveCacheToLocalStorage = () => {
    if (!isMountedForCallbacks()) return;

    const grid = $(tableRef.current).data('kendoGrid');
    const cacheData = {};

    cacheData.hiddenColumns = grid.columns.filter((column) => column.hidden).map((column) => column.field);
    cacheData.stateFilters = filtersRef.current;
    // eslint-disable-next-line no-underscore-dangle
    cacheData.kendoSortSettings = grid.dataSource._sort;

    const cacheItem = {
      data: cacheData
    };

    localStorage.setItem(kendoCacheKey, kendo.stringify(cacheItem));
  };

  const loadCacheFromLocalStorage = () => {
    if (!localStorage.getItem(kendoCacheKey)) return;

    try {
      const cacheItem = JSON.parse(localStorage.getItem(kendoCacheKey));

      const cacheData = cacheItem.data;
      const grid = $(tableRef.current).data('kendoGrid');

      if (cacheData.hiddenColumns) {
        cacheData.hiddenColumns.forEach((fieldName) => grid.hideColumn(fieldName));

        grid.columns
          .map((column) => column.field)
          .filter((column) => !cacheData.hiddenColumns.includes(column))
          .forEach((fieldName) => grid.showColumn(fieldName));
      } else {
        grid.columns.map((column) => column.field).forEach((fieldName) => grid.showColumn(fieldName));
      }

      // eslint-disable-next-line no-underscore-dangle
      if (cacheData.kendoSortSettings) grid.dataSource._sort = cacheData.kendoSortSettings;
      if (cacheData.stateFilters) {
        dispatch({ type: actionTypes.LOAD_FILTERS_FROM_CACHE, filters: cacheData.stateFilters });
      }
    } catch {
      console.log(`Issue with Kendo grid cache: ${kendoCacheKey}`);
      localStorage.removeItem(kendoCacheKey);
    }
  };

  const reloadTable = () => {
    const filtersData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(state.filters)) {
      if (!value) continue;
      filtersData.push({ field: key, operator: 'eq', value });
    }

    if (state.search) {
      filtersData.push({ field: 'search', operator: 'contains', value: state.search });
    }

    $(tableRef.current).data('kendoGrid').dataSource.filter(filtersData);
  };

  const onResetFilters = () => {
    dispatch({ type: actionTypes.FILTERS_RESET });
    dispatch({ type: actionTypes.TABLE_RELOADED });
  };

  // For more complicated scenarios, when you have issues with cached Kendo Grid,
  // to clear all Kendo Grid caches, use the next snippet:
  //
  // Object
  //   .keys(localStorage)
  //   .filter(key => key.startsWith('kendo/'))
  //   .forEach(key => {
  //     console.log("Removing item: ", key);
  //     localStorage.removeItem(key)
  //   });
  //
  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  const mapOptionsForSelect = (optionsForSelect) => {
    return optionsForSelect.map((option) => {
      return { label: option[0], value: option[1] };
    });
  };

  const onChangeReactFilter = (fieldName) => {
    return (value) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const filtersList = [];
  if (statusesForSelect.length) {
    filtersList.push({
      field: 'status',
      locale: 'status',
      type: FilterTypes.GenericMultiSelect,
      boolean: true,
      optionsForSelect: mapOptionsForSelect(statusesForSelect).map((opt) => ({
        title: opt.label,
        value: opt.value.toString()
      })),
      value: state.filters.status?.toString() ? [state.filters.status.toString()] : [],
      single: true,
      disableSorting: true,
      onChange: (value) => {
        onChangeReactFilter('status')(value[0]);
      },
      active: true
    });
  }

  if (categoriesForSelect.length) {
    filtersList.push({
      field: 'category',
      locale: 'category',
      type: FilterTypes.GenericMultiSelect,
      optionsForSelect: mapOptionsForSelect(categoriesForSelect).map((opt) => ({
        title: opt.label,
        value: opt.value
      })),
      value: state.filters.category,
      onChange: onChangeReactFilter('category'),
      active: true
    });
  }

  if (serviceTypesForSelect.length) {
    filtersList.push({
      field: 'serviceType',
      locale: 'service_type',
      type: FilterTypes.GenericMultiSelect,
      optionsForSelect: mapOptionsForSelect(serviceTypesForSelect).map((opt) => ({
        title: opt.label,
        value: opt.value
      })),
      value: state.filters.serviceType,
      onChange: onChangeReactFilter('serviceType'),
      active: true
    });
  }

  if (materialTypesForSelect.length) {
    filtersList.push({
      field: 'materialType',
      locale: 'material_type',
      type: FilterTypes.GenericMultiSelect,
      optionsForSelect: mapOptionsForSelect(materialTypesForSelect).map((opt) => ({
        title: opt.label,
        value: opt.value
      })),
      value: state.filters.materialType,
      onChange: onChangeReactFilter('materialType'),
      active: true
    });
  }
  // ===================== HTML Part ================================
  return (
    <div ref={containerRef}>
      <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filtersList} toggleDisabled>
        <div className="filters__group--search">
          <SearchPanel value={state.search} placeholder={I18n.t('filters.search')} onChange={searchHandler} />
        </div>
      </FilterPanel>

      <article className="--scroll-x --disable-back-gesture">
        <div
          ref={tableRef}
          id="proposed-corrections-grid"
          data-src-url={proposedCorrectionsUrl}
          className="qmb-table"
        />
      </article>
    </div>
  );
}

IndexTable.propTypes = {
  proposedCorrectionsUrl: PropTypes.string.isRequired,
  defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string),
  nonHidableColumns: PropTypes.arrayOf(PropTypes.string),
  namespace: PropTypes.string,
  statusesForSelect: PropTypes.arrayOf(PropTypes.array),
  categoriesForSelect: PropTypes.arrayOf(PropTypes.array),
  serviceTypesForSelect: PropTypes.arrayOf(PropTypes.array),
  materialTypesForSelect: PropTypes.arrayOf(PropTypes.array),
  cacheKey: PropTypes.string,
  pageSize: PropTypes.number
};

IndexTable.defaultProps = {
  namespace: DEFAULT_NAMESPACE,
  statusesForSelect: [],
  categoriesForSelect: [],
  serviceTypesForSelect: [],
  materialTypesForSelect: [],
  cacheKey: DEFAULT_CACHE_KEY,
  defaultHiddenColumns: [],
  nonHidableColumns: DEFAULT_NON_HIDABLE_COLUMNS,
  pageSize: DEFAULT_PAGE_SIZE
};

export default IndexTable;

/* eslint-enable react/no-unescaped-entities */
