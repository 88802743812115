import { buildInitialState as buildInitialDateRangeState } from '@components/Kendo/DateRangePickerReducer';
import LinkTableCell from '@components/Kendo/LinkTableCell';
import { translate } from '@utils/i18n';
import StatusCell from './nested/StatusCell';
import DescriptionCell from './nested/DescriptionCell';
import ScheduledDateCell from './nested/ScheduledDateCell';

export const namespace = 'features.inspections_index';

export const CACHE_KEY = 'kendo/inspections/index';

export const INITIAL_PAGE_SKIP = 0;

export const INITIAL_PAGE = { skip: INITIAL_PAGE_SKIP, take: 25 };

export const INITIAL_SORT = [
  {
    field: 'id',
    dir: 'desc'
  }
];

export const INITIAL_FILTERS = {
  values: {
    account: '',
    building: '',
    technician: null,
    technicianTeam: '',
    status: '',
    frequency: '',
    territory: '',
    buildingStatus: '',
    subcontractor: '',
    inspectionType: '',
    noAccess: '',
    zipCode: '',
    referenceNumber: '',
    workOrder: '',
    tags: '',
    tce: '',
    tceErrors: '',
    asset: '',
    inspectionSeries: '',
    ...buildInitialDateRangeState('scheduledDate'),
    ...buildInitialDateRangeState('inspectionDate'),
    ...buildInitialDateRangeState('dueDate')
  },
  active: {
    account: false,
    building: true,
    technician: true,
    technicianTeam: false,
    status: true,
    frequency: true,
    territory: false,
    buildingStatus: false,
    subcontractor: false,
    inspectionType: false,
    noAccess: false,
    zipCode: false,
    referenceNumber: false,
    workOrder: false,
    tags: false,
    tce: false,
    tceErrors: false,
    asset: true,
    scheduledDate: true,
    inspectionDate: false,
    dueDate: false,
    inspectionSeries: false
  },
  operators: {
    zipCode: 'contains',
    referenceNumber: 'contains',
    workOrder: 'contains'
  }
};

export const ALL_COLUMNS = [
  'selected',
  'id',
  'workOrderNumber',
  'referenceNumber',
  'frequency',
  'description',
  'scheduledDate',
  'inspectionDate',
  'dueDate',
  'assignedTo',
  'building',
  'zipCode',
  'status',
  'inspectionType'
];
export const ALL_COLUMNS_DATA = {
  selected: {
    id: 'selected',
    field: 'selected',
    sortable: false,
    title: translate('table.headers.selected', { namespace }),
    width: 64,
    className: 'k-table-td --selector',
    headerClassName: 'k-table-th --selector'
  },
  id: {
    id: 'id',
    field: 'id',
    title: translate('table.headers.id', { namespace }),
    width: 130,
    className: 'k-table-td --primary',
    headerClassName: 'k-table-th --primary',
    cell: LinkTableCell
  },
  workOrderNumber: {
    id: 'workOrderNumber',
    field: 'workOrderNumber',
    title: translate('table.headers.work_order_number', { namespace }),
    width: 130
  },
  referenceNumber: {
    id: 'referenceNumber',
    field: 'referenceNumber',
    title: translate('table.headers.reference_number', { namespace }),
    width: 130
  },
  frequency: {
    id: 'frequency',
    field: 'frequency',
    title: translate('table.headers.frequency', { namespace }),
    width: 120
  },
  description: {
    id: 'description',
    field: 'description',
    title: translate('table.headers.description', { namespace }),
    width: 250,
    cell: DescriptionCell
  },
  scheduledDate: {
    id: 'scheduledDate',
    field: 'scheduledDate',
    title: translate('table.headers.scheduled_date', { namespace }),
    width: 130,
    cell: ScheduledDateCell
  },
  inspectionDate: {
    id: 'inspectionDate',
    field: 'inspectionDate',
    title: translate('table.headers.inspection_date', { namespace }),
    width: 120
  },
  dueDate: {
    id: 'dueDate',
    field: 'dueDate',
    title: translate('table.headers.due_date', { namespace }),
    width: 120
  },
  assignedTo: {
    id: 'assignedTo',
    field: 'technician',
    title: translate('table.headers.assigned_to', { namespace }),
    width: 150,
    cell: LinkTableCell
  },
  building: {
    id: 'building',
    field: 'building',
    title: translate('table.headers.building', { namespace }),
    width: 250,
    cell: LinkTableCell
  },
  zipCode: {
    id: 'zipCode',
    field: 'zipCode',
    title: translate('table.headers.zip_code', { namespace }),
    width: 100
  },
  status: {
    id: 'status',
    field: 'status',
    title: translate('table.headers.status', { namespace }),
    width: 150,
    cell: StatusCell
  },
  inspectionType: {
    id: 'inspectionType',
    field: 'inspectionType',
    title: translate('table.headers.inspection_type', { namespace }),
    width: 150
  }
};
