import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';

function ActionsCell({ colSpan, dataItem, field, className, ariaColumnIndex, style, actionsList, children }) {
  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const onActionMenuClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  return (
    <td
      colSpan={colSpan}
      className={className}
      role="gridcell"
      aria-colindex={ariaColumnIndex}
      aria-selected="false"
      style={style}>
      {children}
      <button ref={anchor} type="button" onClick={onActionMenuClick} className="qmb-control--icon--row-actions --react">
        <i className="fa-regular fa-ellipsis" />
      </button>
      <Tooltip anchorElement="target" position="left">
        <Popup anchor={anchor.current} show={open} ref={popup}>
          <div className="qmb-popup--action-list">
            <ul role="presentation">
              {actionsList.map((action) => (
                <li key={action.title}>
                  {action.link && (
                    <a title={action.tooltip} disabled={action.disabled} href={action.link}>
                      <i className={`fa-light ${action.faClass}`} />
                      {action.title}
                    </a>
                  )}
                  {action.onClick && (
                    <button
                      disabled={action.disabled}
                      type="button"
                      onClick={action.onClick}
                      title={action.tooltip}
                      style={{ cursor: 'pointer' }}>
                      <i className={`fa-light ${action.faClass}`} />
                      {action.title}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </Popup>
      </Tooltip>
    </td>
  );
}

ActionsCell.propTypes = {
  actionsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      faClass: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      tooltip: PropTypes.string
    })
  ),
  colSpan: PropTypes.number,
  children: PropTypes.node,
  dataItem: PropTypes.shape({
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

ActionsCell.defaultProps = {
  children: undefined
};

export default ActionsCell;
