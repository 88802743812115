var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Popup } from '@progress/kendo-react-popup';
import useDebounce from '@hooks/useDebounce';
import TextValueComp from './TextValueComp';
import { BasicSelect } from '../../BasicSelect/BasicSelect';
import { MAX_ITEMS_PER_PAGE } from '../../BasicSelect/constants';
import { DefaultDebouceDelay } from '../../../constants/configs';
function GenericMultiSelect(_a) {
    var _b;
    var { dataTitleField = 'label', dataSubTitleField = 'address', dataIconField = 'icon', dataImgField = 'photoUrl', dataColorField = 'color', disableSorting = true, value = [], fixedOptions } = _a, params = __rest(_a, ["dataTitleField", "dataSubTitleField", "dataIconField", "dataImgField", "dataColorField", "disableSorting", "value", "fixedOptions"]);
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const searchValue = useDebounce(searchText, DefaultDebouceDelay);
    const [loadingValues, setLoadingValues] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const pagesLoading = useRef(0);
    const page = useRef(0);
    const [open, setOpen] = useState(false);
    const rootAnchor = useRef(null);
    const popup = useRef(null);
    useEffect(() => {
        page.current = 0;
        if (fixedOptions && fixedOptions.length > 0)
            return;
        setOptions((curState) => {
            return curState.filter((option) => value === null || value === void 0 ? void 0 : value.includes(option.value)).map((opt) => (Object.assign(Object.assign({}, opt), { hide: true })));
        });
        void loadMoreOptions();
    }, [searchValue.trim()]);
    useEffect(() => {
        if (fixedOptions && fixedOptions.length > 0) {
            setOptions(fixedOptions);
        }
    }, [fixedOptions]);
    useEffect(() => {
        const initialValues = Array.isArray(value) ? value : [value].filter(Boolean);
        if (initialValues.length === 0 && !open) {
            setSearchText('');
        }
    }, [value]);
    useEffect(() => {
        const initialValues = Array.isArray(value) ? value : [value].filter(Boolean);
        if (initialValues.some((val) => ![...(params.additionalOptions || []), ...options].some((option) => option.value === val))) {
            void getMissingValues(initialValues);
        }
    }, [(_b = value === null || value === void 0 ? void 0 : value.join) === null || _b === void 0 ? void 0 : _b.call(value, '')]);
    useEffect(() => {
        if (!open) {
            setSearchText('');
        }
    }, [open]);
    const getMissingValues = (initialValues) => __awaiter(this, void 0, void 0, function* () {
        if (fixedOptions && fixedOptions.length > 0)
            return;
        setLoadingValues(true);
        yield Promise.all(initialValues
            .filter((val) => ![...(params.additionalOptions || []), ...options].some((option) => option.value === val))
            .map((val) => __awaiter(this, void 0, void 0, function* () {
            const res = yield fetch(`${params.valueMapUrl}&id=${val}`);
            const data = (yield res.json());
            const tableFindParam = {
                take: '1',
                skip: data
            };
            const searchParams = new URLSearchParams(tableFindParam);
            const response = yield fetch(`${params.dataUrl}&${searchParams.toString()}`);
            const { data: [itemInfo] } = 
            // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
            (yield response.json());
            setOptions((prev) => {
                const filledOption = {
                    value: val,
                    title: itemInfo[dataTitleField],
                    subtitle: itemInfo[dataSubTitleField],
                    icon: params.allowLetterAvatar && val === 0 ? 'fa-light fa-user-helmet-safety' : itemInfo[dataIconField],
                    imgUrl: itemInfo[dataImgField],
                    color: itemInfo[dataColorField],
                    hide: true
                };
                if (disableSorting && prev.some((opt) => opt.value === val))
                    return prev;
                return [filledOption, ...prev.filter((opt) => opt.value !== val)];
            });
        })));
        setLoadingValues(false);
    });
    const onClickAway = (e) => {
        if (e.target !== rootAnchor.current) {
            setOpen(false);
        }
    };
    const loadMoreOptions = () => __awaiter(this, void 0, void 0, function* () {
        if (fixedOptions && fixedOptions.length > 0)
            return;
        setLoadingOptions(true);
        pagesLoading.current += 1;
        const filterParams = Object.assign({ take: MAX_ITEMS_PER_PAGE.toString(), skip: (page.current * MAX_ITEMS_PER_PAGE).toString() }, (searchValue.trim() !== '' && {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][value]': searchValue.trim(),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][field]': 'label',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][operator]': 'contains',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[filters][0][ignoreCase]': 'true',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filter[logic]': 'and'
        }));
        const searchParams = new URLSearchParams(filterParams);
        const response = yield fetch(`${params.dataUrl}&${searchParams.toString()}`);
        const { data, total_count: totalItems } = 
        // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
        (yield response.json());
        setOptions((currentOptions) => {
            const newOptions = [
                ...currentOptions.filter((elem) => disableSorting ? !data.some((opt) => `${opt.value}` === `${elem.value}`) : true),
                ...data
                    .filter((elem) => (disableSorting ? true : !currentOptions.some((opt) => `${opt.value}` === `${elem.value}`)))
                    .map((elem) => ({
                    value: elem.value,
                    title: elem[dataTitleField],
                    subtitle: elem[dataSubTitleField],
                    icon: params.allowLetterAvatar && `${elem.value}` === '0'
                        ? 'fa-light fa-user-helmet-safety'
                        : elem[dataIconField],
                    imgUrl: elem[dataImgField],
                    color: elem[dataColorField]
                }))
            ];
            return newOptions;
        });
        setTotalCount(totalItems);
        setLoadingOptions(false);
        pagesLoading.current -= 1;
    });
    const loadMore = () => {
        if (fixedOptions && fixedOptions.length > 0)
            return;
        if (pagesLoading.current > 0)
            return;
        page.current += 1;
        void loadMoreOptions();
    };
    const textValue = (value === null || value === void 0 ? void 0 : value.length) > 0 ? (React.createElement(TextValueComp, { allowLetterAvatar: Boolean(params.allowLetterAvatar), internalValue: value, options: [...(params.additionalOptions || []), ...options] })) : (params.label);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: `qmb-control--menu multiSelectInput ${(value === null || value === void 0 ? void 0 : value.length) === 0 && !loadingValues ? 'items-center' : ''}`, type: "button", ref: rootAnchor, onClick: () => setOpen(!open) }, loadingValues ? React.createElement("span", { className: "k-icon k-i-loading k-input-loading-icon" }) : textValue),
        (value === null || value === void 0 ? void 0 : value.length) > 0 && (React.createElement("label", { className: "qmb-label multiSelectInputLabel" },
            params.label,
            " ",
            !params.single && React.createElement("b", null, value === null || value === void 0 ? void 0 : value.length))),
        React.createElement(Popup, { anchor: rootAnchor.current, show: open, ref: popup },
            React.createElement(ClickAwayListener, { onClickAway: onClickAway },
                React.createElement("div", null,
                    React.createElement(BasicSelect, Object.assign({ totalCount: totalCount, onLoadMore: loadMore, loadingOptions: loadingOptions, searchText: searchText, onChangeSearchText: setSearchText, fixedOptions: options.filter((opt) => !opt.hide), value: value, onReset: () => {
                            setOpen(false);
                            params.onChange([]);
                        } }, params)))))));
}
export default GenericMultiSelect;
