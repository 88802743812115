import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import RadioGroup from '../../../../components/RadioGroup/RadioGroup';

const namespace = 'features.work_orders';

export default function FormTypeSelector({ isVendor, onChange }) {
  const onChangeType = (type) => {
    onChange({ isVendor: type === 'subcontractor' });
  };

  return (
    <div className="formTypeSelector">
      <div>{translate('who_will_be_performing', { namespace })}</div>
      <RadioGroup
        name="assigneeType"
        label={`${translate('assign_to', { namespace })}:`}
        onChange={onChangeType}
        options={[
          { label: translate('our_technicians', { namespace }), value: 'technicians' },
          { label: translate('subcontractor', { namespace }), value: 'subcontractor' }
        ]}
        value={isVendor ? 'subcontractor' : 'technicians'}
      />
    </div>
  );
}

FormTypeSelector.propTypes = {
  isVendor: PropTypes.bool,
  onChange: PropTypes.func
};

FormTypeSelector.defaultProps = {
  isVendor: false,
  onChange: () => {}
};
