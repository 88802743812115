import React, { useCallback, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import KendoActionsCell from '@components/Kendo/ActionsCell';
import { Dialog } from '@progress/kendo-react-dialogs';
import I18n, { translate } from '@utils/i18n';
import ColumnMenuContext from '@components/Kendo/ColumnMenuContext';
import {
  subcontractorPath,
  activateToggleSubcontractorPath,
  editSubcontractorPath,
  resendSubcontractorInvitePath,
  cancelSubcontractorPath
} from 'routes';
import { Tooltip } from '@progress/kendo-react-tooltip';
import api from '@utils/axios';

const namespace = 'features.subcontractors.index.actions_cell';

function ActionsCell({ colSpan, dataItem, field, className, ariaColumnIndex, style }) {
  const { onDataChange } = useContext(ColumnMenuContext);
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
  const [destroyDialogVisible, setDestroyDialogVisible] = useState(false);

  const onResend = useCallback(() => {
    api.post(resendSubcontractorInvitePath(dataItem.id)).then(() => onDataChange());
  }, []);

  const onConfirmCancel = () => {
    api.post(cancelSubcontractorPath({ id: dataItem.id })).then(() => onDataChange());
  };

  const onConfirmDestroy = () => {
    api.delete(subcontractorPath({ id: dataItem.id })).then(() => onDataChange());
  }

  const onCancel = () => toggleCancelDialog();

  const onDestroy = () => toggleDestroyDialog();

  const toggleCancelDialog = () => setCancelDialogVisible((prev) => !prev);

  const toggleDestroyDialog = () => setDestroyDialogVisible((prev) => !prev);

  const actionsList = useMemo(() => {
    const list = [];
    if (dataItem.canEdit) {
      list.push({
        title: I18n.t('generic.edit_details'),
        faClass: 'fa-pen-to-square',
        link: editSubcontractorPath(dataItem.id)
      });
    }

    if (dataItem.canResend) {
      list.push({
        title: I18n.t('generic.re_send'),
        faClass: 'fa-paper-plane',
        onClick: onResend
      });
    }

    if (dataItem.canCancel) {
      list.push({
        title: I18n.t('generic.cancel_invite'),
        faClass: 'fa-ban red',
        onClick: onCancel
      });
    }

    list.push({
      title: I18n.t('generic.delete'),
      faClass: 'fa-trash-can red',
      onClick: onDestroy,
      disabled: !dataItem.canDestroy,
      tooltip: dataItem.canDestroy ? '' : translate('cannot_destroy', { namespace })
    });

    return list;
  }, []);

  const toggleTitle = useMemo(() => {
    if (!dataItem.canActivateToggle) return translate('cannot_deactivate', { namespace });

    if (dataItem.active) return translate('click_to_deactivate', { namespace });

    return translate('click_to_activate', { namespace });
  }, []);

  const toggleActive = () => {
    api
      .post(activateToggleSubcontractorPath(dataItem.id))
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  // if (actionsList.length === 0)
  //   return (
  //     <td
  //       colSpan={colSpan}
  //       className={className}
  //       role="gridcell"
  //       aria-colindex={ariaColumnIndex}
  //       aria-selected="false"
  //       style={style}>
  //       <button
  //         type="button"
  //         onClick={toggleActive}
  //         disabled={!dataItem.canActivateToggle}
  //         className={`qmb-control--icon--toggle${dataItem.active ? '--active' : ''}`}
  //         style={{ marginRight: '1rem' }}>
  //         <Tooltip anchorElement="target" position="bottom">
  //           <i className={`fa-light ${dataItem.active ? 'fa-eye' : 'fa-eye-slash'}`} title={toggleTitle} />
  //         </Tooltip>
  //       </button>
  //     </td>
  //   );

  return (
    <>
      <KendoActionsCell
        colSpan={colSpan}
        dataItem={dataItem}
        field={field}
        className={className}
        ariaColumnIndex={ariaColumnIndex}
        style={style}
        actionsList={actionsList}>
        {dataItem.statusRaw !== 'accepted' && (<span style={{ marginRight: '42px' }}/>)}
        {dataItem.statusRaw === 'accepted' && (
          <button
            type="button"
            onClick={toggleActive}
            disabled={!dataItem.canActivateToggle}
            className={`qmb-control--icon--toggle${dataItem.active ? '--active' : ''}`}
            style={{ marginRight: '1rem' }}>
            <Tooltip anchorElement="target" position="bottom">
              <i className={`fa-light ${dataItem.active ? 'fa-eye' : 'fa-eye-slash'}`} title={toggleTitle} />
            </Tooltip>
          </button>
        )}
      </KendoActionsCell>

      {cancelDialogVisible && (
        <Dialog title={translate('confirm_cancel', { namespace })} className="qmb-dialog" onClose={toggleCancelDialog}>
          <p>{translate('cancel_invite', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={toggleCancelDialog}>
              {I18n.t('generic.cancel')}
            </button>
            <button type="button" className="qmb-button--submit" onClick={onConfirmCancel}>
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}

      {destroyDialogVisible && (
        <Dialog title={translate('confirm_destroy', { namespace })} className="qmb-dialog" onClose={toggleDestroyDialog}>
          <p>{translate('destroy_text', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <button type="button" className="qmb-button" onClick={toggleDestroyDialog}>
              {I18n.t('generic.cancel')}
            </button>
            <button type="button" className="qmb-button--submit" onClick={onConfirmDestroy}>
              {I18n.t('generic.ok')}
            </button>
          </div>
        </Dialog>
      )}
    </>
  );
}

ActionsCell.propTypes = {
  ariaColumnIndex: PropTypes.number.isRequired,
  style: PropTypes.object,
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  colSpan: PropTypes.number.isRequired,
  dataItem: PropTypes.shape({
    isResolved: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.object),
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

ActionsCell.defaultProps = {
  style: null,
  className: ''
};

export default ActionsCell;
